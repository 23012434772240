import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CBtnList,
  CanchorCard,
  CCenterSlider,
  CRoomDesc,
  CDefinition,
  AssetImage,
  LOtherFloors,
  LWifi,
  ConceptMedia,
  CContentBox,
  LStayContact,
  LPlan,
} from '../../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        type="ja"
        size="large"
        data={{
          title: {
            main: 'レギュラーフロア',
            sub: '52F 〜 56F',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/floor/52_regular_floor/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/floor/52_regular_floor/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'ご宿泊',
                path: '/stay/',
              },
              {
                label: '客室',
                path: '/stay/floor/',
              },
            ],
            current: {
              label: 'レギュラーフロア',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mt60">
        <LWrap>
          <p className="u_tac">
            ベーシックなレギュラーフロアには、ファミリールームやバリアフリールームなど、多彩な客室がございます。
          </p>
          <CanchorCard
            exClass="u_pb0 u_mt50_sp"
            col={2}
            data={[
              {
                img: {
                  src: '/assets/images/stay/floor/52_regular_floor/img_anchor.png',
                  alt: '',
                },
                link: {
                  href: '#comfortDouble',
                },
                title: {
                  main: 'COMFORT DOUBLE',
                  sub: 'コンフォートダブル',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/floor/52_regular_floor/img_anchor02.png',
                  alt: '',
                },
                link: {
                  href: '#cornerDouble',
                },
                title: {
                  main: 'CORNER DOUBLE',
                  sub: 'コーナーダブル',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/floor/52_regular_floor/img_anchor03.png',
                  alt: '',
                },
                link: {
                  href: '#modereteDouble',
                },
                title: {
                  main: 'MODERATE DOUBLE',
                  sub: 'モデレートダブル',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/floor/52_regular_floor/img_anchor04.png',
                  alt: '',
                },
                link: {
                  href: '#comfortTwin',
                },
                title: {
                  main: 'COMFORT TWIN',
                  sub: 'コンフォートツイン',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/floor/52_regular_floor/img_anchor05.png',
                  alt: '',
                },
                link: {
                  href: '#deluxeTwin',
                },
                title: {
                  main: 'DELUXE TWIN',
                  sub: 'デラックスツイン',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/floor/52_regular_floor/img_anchor06.png',
                  alt: '',
                },
                link: {
                  href: '#cornerTwin',
                },
                title: {
                  main: 'CORNER TWIN',
                  sub: 'コーナーツイン',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/floor/52_regular_floor/img_anchor07.png',
                  alt: '',
                },
                link: {
                  href: '#familyRoom',
                },
                title: {
                  main: 'FAMILY ROOM',
                  sub: 'ファミリールーム',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <div className="u_pt90 u_bgAccent">
        <LWrap exClass="u_mb90">
          <section className="" id="comfortDouble">
            <CSectTitle
              title={{
                ja: 'コンフォートダブル',
                en: (
                  <>
                    COMFORT <br className="u_sp" />
                    DOUBLE
                  </>
                ),
              }}
            />
            <p className="u_tac u_tal_sp u_mb30">
              ビジネス利用やプライベートステイに。
              <br />
              1～2名様のご利用に最適です。
            </p>
            <CCenterSlider
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_comfort.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_comfort02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_comfort.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_comfort02.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CRoomDesc
              exClass="u_mb60"
              left={
                <CDefinition
                  data={[
                    {
                      title: '広さ',
                      text: <>27m²</>,
                    },
                    {
                      title: '人数',
                      text: <>1～2名</>,
                    },
                    {
                      title: 'ベッドサイズ',
                      text: <>幅154cm×長さ210cm</>,
                    },
                    {
                      title: '部屋数',
                      text: <>34室（禁煙）</>,
                    },
                  ]}
                />
              }
              right={
                <div className="">
                  <AssetImage
                    src="/assets/images/stay/floor/52_regular_floor/img_comfort_floor.png"
                    alt=""
                  ></AssetImage>
                </div>
              }
            />
          </section>
          <section className="u_pt90" id="cornerDouble">
            <CSectTitle
              title={{
                ja: 'コーナーダブル',
                en: 'CORNER DOUBLE',
              }}
            />
            <p className="u_tac u_tal_sp u_mb30">
              角部屋のコーナールームは、バスルームの丸窓からも眺望がお楽しみいただけます。
            </p>
            <CCenterSlider
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_corner_double.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_corner_double04.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_corner_double.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_corner_double04.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CRoomDesc
              exClass="u_mb60"
              left={
                <CDefinition
                  data={[
                    {
                      title: '広さ',
                      text: <>47m²</>,
                    },
                    {
                      title: '人数',
                      text: <>1～2名</>,
                    },
                    {
                      title: 'ベッドサイズ',
                      text: <>幅180cm×長さ210cm</>,
                    },
                    {
                      title: '部屋数',
                      text: <>19室（禁煙）</>,
                    },
                  ]}
                />
              }
              right={
                <div className="">
                  <AssetImage
                    src="/assets/images/stay/floor/52_regular_floor/img_corner_double_floor.png"
                    alt=""
                  ></AssetImage>
                </div>
              }
            />
          </section>
          <section className="u_pt90" id="modereteDouble">
            <CSectTitle
              title={{
                ja: 'モデレートダブル',
                en: (
                  <>
                    MODERATE <br className="u_sp" />
                    DOUBLE
                  </>
                ),
              }}
            />
            <p className="u_tac u_tal_sp u_mb30">
              ラグジュアリーな雰囲気のダブルルームです。
            </p>
            <CCenterSlider
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_moderete_double.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_moderete_double.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CRoomDesc
              exClass="u_mb60"
              left={
                <CDefinition
                  data={[
                    {
                      title: '広さ',
                      text: <>38m²</>,
                    },
                    {
                      title: '人数',
                      text: <>1～2名</>,
                    },
                    {
                      title: 'ベッドサイズ',
                      text: <>幅154cm×長さ210cm</>,
                    },
                    {
                      title: '部屋数',
                      text: <>6室（禁煙）</>,
                    },
                  ]}
                />
              }
              right={
                <div className="">
                  <AssetImage
                    src="/assets/images/stay/floor/52_regular_floor/img_moderete_double_floor.png"
                    alt=""
                  ></AssetImage>
                </div>
              }
            />
          </section>

          <section className="u_pt90" id="comfortTwin">
            <CSectTitle
              title={{
                ja: 'コンフォートツイン',
                en: 'COMFORT TWIN',
              }}
            />
            <p className="u_tac u_tal_sp u_mb30">
              すっきりしたレイアウトで快適な居住空間のツインルームです。
            </p>
            <CCenterSlider
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_comfort_twin.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_comfort_twin.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CRoomDesc
              exClass="u_mb60"
              left={
                <CDefinition
                  data={[
                    {
                      title: '広さ',
                      text: <>37m²</>,
                    },
                    {
                      title: '人数',
                      text: <>1～2名</>,
                    },
                    {
                      title: 'ベッドサイズ',
                      text: <>幅120cm×長さ210cm×2台</>,
                    },
                    {
                      title: '部屋数',
                      text: <>34室（禁煙）</>,
                    },
                  ]}
                />
              }
              right={
                <div className="">
                  <AssetImage
                    src="/assets/images/stay/floor/52_regular_floor/img_comfort_twin_floor.png"
                    alt=""
                  ></AssetImage>
                </div>
              }
            />
          </section>
          <section className="u_pt90" id="deluxeTwin">
            <CSectTitle
              title={{
                ja: 'デラックスツイン',
                en: (
                  <>
                    DELUXE <br className="u_sp" />
                    TWIN
                  </>
                ),
              }}
            />
            <p className="u_tac u_tal_sp u_mb30">
              ゆったりとしたレイアウトと大きな窓が特徴。
              <br />
              エキストラベッド使用で3名様までご利用いただけます。
            </p>
            <CCenterSlider
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_deluxe_twin.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_deluxe_twin.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CRoomDesc
              exClass="u_mb60"
              left={
                <CDefinition
                  data={[
                    {
                      title: '広さ',
                      text: <>41m²</>,
                    },
                    {
                      title: '人数',
                      text: <>1〜3名</>,
                    },
                    {
                      title: 'ベッドサイズ',
                      text: <>幅120cm×長さ210cm×2台</>,
                    },
                    {
                      title: '部屋数',
                      text: <>74室（禁煙）</>,
                    },
                  ]}
                />
              }
              right={
                <div className="">
                  <AssetImage
                    src="/assets/images/stay/floor/52_regular_floor/img_deluxe_twin_floor.png"
                    alt=""
                  ></AssetImage>
                </div>
              }
            />
          </section>
          <section className="u_pt90" id="cornerTwin">
            <CSectTitle
              title={{
                ja: 'コーナーツイン',
                en: 'CORNER TWIN',
              }}
            />
            <p className="u_tac u_tal_sp u_mb30">
              角部屋のコーナールームは、バスルームの丸窓からも眺望がお楽しみいただけます。
            </p>
            <CCenterSlider
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_corner_twin.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_corner_twin02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_corner_twin04.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_corner_twin.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_corner_twin02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_corner_twin04.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CRoomDesc
              exClass="u_mb60"
              left={
                <CDefinition
                  data={[
                    {
                      title: '広さ',
                      text: <>47m²</>,
                    },
                    {
                      title: '人数',
                      text: <>1〜2名</>,
                    },
                    {
                      title: 'ベッドサイズ',
                      text: <>幅110cm×長さ210cm×2台</>,
                    },
                    {
                      title: '部屋数',
                      text: <>21室（禁煙）</>,
                    },
                  ]}
                />
              }
              right={
                <div className="">
                  <AssetImage
                    src="/assets/images/stay/floor/52_regular_floor/img_corner_twin_floor.png"
                    alt=""
                  ></AssetImage>
                </div>
              }
            />
          </section>
          <section className="u_pt90" id="familyRoom">
            <CSectTitle
              title={{
                ja: 'ファミリールーム',
                en: 'FAMILY ROOM',
              }}
            />
            <p className="u_tac u_tal_sp u_mb30">
              4名様までご宿泊いただける客室です。
              <br />
              窓際には靴を脱いで寛げるフローリングスペースもございます。
            </p>
            <CCenterSlider
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_family_room.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_family_room02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_family_room03.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_family_room.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_family_room02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/52_regular_floor/img_family_room03.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CRoomDesc
              exClass="u_mb60"
              left={
                <CDefinition
                  data={[
                    {
                      title: '広さ',
                      text: <>57m²</>,
                    },
                    {
                      title: '人数',
                      text: <>4名</>,
                    },
                    {
                      title: 'ベッドサイズ',
                      text: (
                        <>
                          幅120cmx長さ210cmx3台
                          <br />
                          幅110cmx長さ210cmx1台
                        </>
                      ),
                    },
                    {
                      title: '部屋数',
                      text: <>6室（禁煙）</>,
                    },
                  ]}
                />
              }
              right={
                <div className="">
                  <AssetImage
                    src="/assets/images/stay/floor/52_regular_floor/img_family_room_floor.png"
                    alt=""
                  ></AssetImage>
                </div>
              }
            />
          </section>
        </LWrap>
      </div>
      <section className="u_bgWhite l_sect">
        <LWrap>
          <CSectTitle
            title={{
              ja: <>スペック</>,
              en: <>SPEC</>,
            }}
          />
          <h4 className="c_headingLv4 u_mb15 u_tal">客室設備・備品</h4>
          <p className="u_mb30">
            ベッド （シモンズ社製マットレス） ／
            液晶テレビ（地上デジタル放送　BS放送　WOWOW） ／
            タッチパネル型コントロールパネル ／ 加湿空気清浄機 ／ 電話 ／
            室内金庫 ／ 冷蔵庫 ／ 電気ポット ／ 日本茶 ／ ミネラルウォーター ／
            タオル・バスタオル ／ スリッパ ／ ヘアドライヤー ／ パジャマ
          </p>
          <h4 className="c_headingLv4 u_mb15 u_tal">アメニティグッズ</h4>
          <p className="u_mb30">
            バスアメニティ(シャンプー・コンディショナー・ボディウォッシュ) ／
            歯ブラシ・歯磨き粉 ／ ひげそり・シェイビングフォーム ／
            綿棒・コットンセット・ヘアブラシ
          </p>
          <h4 className="c_headingLv4 u_mb15 u_tal">通信環境</h4>
          <p className="u_mb30">Wi-Fi 接続無料 ／ 電話回線／ TV</p>
          <h4 className="c_headingLv4 u_mb15 u_tal">貸出備品</h4>
          <p className="u_mb30">
            ブルーレイ・CDプレイヤー ／ アイロン・アイロン台 ／ ズボンプレッサー
            ／ 電気スタンド ／ 花瓶 ／ ベビーベッド ／ ベッドガード
            <ul className="c_noteList u_mt10">
              <li>台数に限りがございますので、ご予約をおすすめいたします。</li>
              <li>
                上記以外にも貸出備品を取り揃えております。詳しくはお問合せください。
              </li>
            </ul>
          </p>
          <h4 className="c_headingLv4 u_mb15 u_tal">サービス</h4>
          <p className="u_mb30">
            <Link to="/stay/room_service/">ルームサービス</Link>
            <br />
            <Link to="/stay/relaxation/">インルームリラクゼーション</Link>
            <br />
            ランドリー
            <br />
            無料インターネット（Wi-Fi）
            <br />
            <Link to="/about/access/car/">駐車場</Link>
          </p>
          <CBtnList
            data={[
              {
                label: '客室一覧',
                link: {
                  href: '/stay/floor/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <LPlan />
      <LOtherFloors />
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LStayContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
